import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Button, Spinner, Modal, ModalHeader } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { getRandomJob } from '../features/job/jobSlice';
import ItemExplainer from './ItemExplainer';
import { useSpeechSynthesis } from 'react-speech-kit';
import NewThingForm from './NewThingForm';
import { createJob } from '../features/job/jobSlice';
import { toast } from 'react-toastify';

function Job() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  const job = useSelector((state) => state.job.job);
  const userCreator = useSelector((state) => state.job.user);
  const user = useSelector((state) => state.auth.user)

  const handleGetRandomJob = async () => {
    setLoading(true);
    try {
      await dispatch(getRandomJob());
    } catch (error) {
      console.error('Error fetching random job:', error);
    } finally {
      setLoading(false);
    }
  };

  const { speak } = useSpeechSynthesis();

  const handlePlayback = (text) => {
    try {
      console.log(`Trying to say ${text}`);
      speak({ text });
    } catch (error) {
      console.error('Error during speech synthesis:', error);
    }
  };

  const handleClipboard = () => {
    const itemToCopy = job.name;
    if (!itemToCopy) {
      return;
    }
    navigator.clipboard.writeText(itemToCopy).then(
      function () {
        alert(`Copied ${itemToCopy} to clipboard`);
      })
      .catch(
        function () {
          alert("Error copying to clipboard!");
        });
  };

  const toggleModal = () => setShowModal(!showModal); // Function to toggle modal visibility

  const handleFormSubmit = async (formData) => {
    // Handle form submission
    try {
      // Dispatch action to create a new place
      await dispatch(createJob(formData));
      // Close the modal after successful submission
      setShowModal(false);
      toast.success(`Success: ${formData.incomingName} added`);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error('A place with the same name already exists.');
      } else {
        console.error('Error submitting form:', error);
        toast.error('An error occurred while submitting the form. Please try again later.');
      }
    }
  };

  return (
    <>
      <Col className="bordered d-grid gap-2">
        <Row className="itemTitle">
          <h2>Jobs!</h2>
        </Row>
        <Row className="randomJob">
          <Button onClick={handleGetRandomJob} disabled={loading} size="lg">
            {loading ?
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {'Loading...'} </>
              : 'Get Random Job'}
          </Button>
        </Row>
        <Row className="display">

          {job ? (<>
            <Button
              variant="info"
            >
              <div>
                <ItemExplainer item={job} user={userCreator} handlePlayback={handlePlayback} handleClipboard={handleClipboard} />
              </div>
            </Button>
          </>
          ) : (<></>
          )}

        </Row>
        <hr />
        <Row>
          <Col>
            {user ? (
              <>
                <Button onClick={toggleModal}>Add your own</Button>
                <Modal show={showModal} onHide={toggleModal}> {/* Modal component with show and onHide props */}
                  <Modal.Header closeButton>
                    <Modal.Title>Add New Job</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <NewThingForm userId={user._id} onSubmit={handleFormSubmit} closeModal={toggleModal} />
                  </Modal.Body>
                </Modal>
              </>
            ) : (
              <>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default Job;

import axios from 'axios'

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL + "prompts/" : process.env.REACT_APP_DEV_API_URL + "prompts/";


// Get random prompt

const getRandomPrompt = async (playerCount) => {
  console.log(`Within promptService, playerCount is ${playerCount}`)
  console.log("getRandomPrompt activated")
  console.log(`API_URL = ${API_URL}`)
  const response = await axios.get(API_URL + `random`, {
    params: { playerCount }
  });
  return response.data
}

// Create new prompt

const createPrompt = async (incomingData, token) => {
  console.log("createPrompt activated")
  console.log(`API_URL = ${API_URL}`)
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_URL + `create`, incomingData, config)

  return response.data
}

// Edit prompt

const editPrompt = async (incomingData, promptId, token) => {
  console.log("editPrompt activated")
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.put(API_URL + promptId, incomingData, config)
  return response.data
}

// Get prompts that need verification

const findPromptsToVerify = async (token) => {
  console.log("Finding prompts that need verifying");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL + `verify`, config)
  // console.log(`service response.data: ${JSON.stringify(response.data)}`)
  return response.data
}

const deletePrompt = async (promptId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  try {
    const response = await axios.delete(API_URL + promptId, config)
    return response.data
  } catch (error) {
    throw error; // Throw any errors
  }
}

const verifyPrompt = async (promptId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const updatedStatus = { verified: true };
  const response = await axios.put(API_URL + promptId, updatedStatus, config)
  return response.data
}

const getAllPrompts = async (token) => {
  console.log(`getAllPrompts activated`)
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + `all`, config)
  // console.log(`getAllPrompts response = ${JSON.stringify(response.data)}`)
  return response.data
}

const promptService = {
  getRandomPrompt,
  createPrompt,
  findPromptsToVerify,
  deletePrompt,
  verifyPrompt,
  getAllPrompts,
  editPrompt
}

export default promptService
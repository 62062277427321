import { createSlice, createAsyncThunk, buildCreateSlice } from "@reduxjs/toolkit";
import promptService from "./promptService";

import axios from "axios";

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;

const initialState = {
  prompt: null,
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get Random Prompt
export const getRandomPrompt = createAsyncThunk(
  'prompts/getRandom',
  async ({ playerCount }, thunkAPI) => {
    console.log(`Within slice, playerCount is ${playerCount}`)
    try {
      const promptResponse = await promptService.getRandomPrompt(playerCount);
      // console.log(`slice promptResponse: ${JSON.stringify(promptResponse)}`)
      const userResponse = await axios.get(`${API_URL}user/${promptResponse.userCreator}`);
      // console.log(`slice userResponse: ${JSON.stringify(userResponse)}`)
      return {
        prompt: promptResponse,
        user: userResponse.data.name
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.messave ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Find prompts that need verification
export const findPromptsToVerify = createAsyncThunk(
  'prompts/findPromptsToVerify',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const promptResponse = await promptService.findPromptsToVerify(token)
      // console.log(`slice promptResponse: ${JSON.stringify(promptResponse)}`)
      const users = await Promise.all(promptResponse.map(async (prompt) => {
        const userResponse = await axios.get(`${API_URL}user/${prompt.userCreator}`);
        return userResponse.data.name; // Return only the username
      }));

      return {
        prompt: promptResponse,
        user: users
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.messave ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deletePrompt = createAsyncThunk(
  'prompts/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await promptService.deletePrompt(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const verifyPrompt = createAsyncThunk(
  'prompts/verify',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await promptService.verifyPrompt(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const createPrompt = createAsyncThunk(
  'prompts/create',
  async (incomingData, thunkAPI) => {
    console.log(`Attempting to create a new prompt using ${JSON.stringify(incomingData)}`);
    try {
      const token = thunkAPI.getState().auth.user.token
      console.log(`token? ${token}`)
      return await promptService.createPrompt(incomingData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)

    }
  }
)

export const getAllPrompts = createAsyncThunk(
  'prompts/findAll',
  async (_, thunkAPI) => {
    console.log(`Attempting to get all prompts.`)
    try {
      const token = thunkAPI.getState().auth.user.token
      console.log(`token? ${token}`)
      const response = await promptService.getAllPrompts(token); // Fetch all prompts
      return response; // Return the entire response object
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const editPrompt = createAsyncThunk(
  'prompts/edit',
  async ({ incomingData, id }, thunkAPI) => {
    console.log(`Attempting to edit a prompt: ${incomingData}`)
    try {
      const token = thunkAPI.getState().auth.user.token
      console.log(`token? ${token}`)
      const response = await promptService.editPrompt(incomingData, id, token);
      console.log(`Response? ${response}`)
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRandomPrompt.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getRandomPrompt.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.prompt = action.payload.prompt;
        state.user = action.payload.user
      })
      .addCase(getRandomPrompt.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(createPrompt.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createPrompt.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
      })
      .addCase(createPrompt.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
      })
      .addCase(findPromptsToVerify.pending, (state) => {
        state.isLoading = true
      })
      .addCase(findPromptsToVerify.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.prompt = action.payload.prompt;
        state.user = action.payload.user
      })
      .addCase(findPromptsToVerify.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
      })
      .addCase(deletePrompt.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletePrompt.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Filter out the deleted prompt from the state
        state.prompt = state.prompt.filter((prompt) => prompt._id !== action.payload._id);
      })
      .addCase(deletePrompt.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(verifyPrompt.pending, (state) => {
        state.isLoading = true
      })
      .addCase(verifyPrompt.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Filter out the verified prompt from the state
        state.prompt = state.prompt.map((prompt) =>
          prompt._id === action.payload._id ? { ...prompt, verified: true } : prompt
        );
      })
      .addCase(verifyPrompt.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getAllPrompts.pending, (state) => {
        state.isLoading = true
        state.prompt = []
      })
      .addCase(getAllPrompts.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.prompt = action.payload;
        state.user = action.payload.user
      })
      .addCase(getAllPrompts.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(editPrompt.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editPrompt.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Find the index of the edited prompt
        const index = state.prompt.findIndex(prompt => prompt._id === action.payload._id);
        // If the prompt is found, update it
        if (index !== -1) {
          state.prompt[index] = action.payload;
        }
      })
      .addCase(editPrompt.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });

  },
})

export const { reset } = promptSlice.actions
export default promptSlice.reducer
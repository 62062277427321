import React, { useState, useEffect } from "react";
import { Row, Container, Button, Col, OverlayTrigger, Tooltip, DropdownButton, DropdownItem, Dropdown, Modal } from "react-bootstrap";
import { FaRandom } from "react-icons/fa";
import { FaArrowRotateLeft, FaUser, FaUserGroup, FaUsers } from 'react-icons/fa6';
import Prompt from "../components/Prompt";
import { useDispatch, useSelector } from "react-redux";
import { createPrompt, getRandomPrompt } from "../features/prompt/promptSlice";
import { MdAddBox, MdDarkMode, MdEdit, MdEngineering, MdGroupOff, MdLogin, MdLogout, MdOutlineDarkMode, MdOutlineLightMode, MdReport } from "react-icons/md";
import { logout, login } from "../features/auth/authSlice";
import Login from "./Login";
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import { IconContext } from "react-icons/lib";
import Report from "./Report";
import AddPrompt from "./AddPrompt";

const Prompts = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const prompt = useSelector((state) => state.prompt);
  const [selectedPlayers, setSelectedPlayers] = useState(0);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleShowLoginModal = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const handleShowReportModal = () => setShowReportModal(true);
  const handleCloseReportModal = () => setShowReportModal(false);
  const [showAddPromptModal, setShowAddPromptModal] = useState(false);
  const handleShowAddPromptModal = () => setShowAddPromptModal(true);
  const handleCloseAddPromptModal = () => setShowAddPromptModal(false);

  const { user } = useSelector((state) => state.auth)

  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
    document.documentElement.setAttribute(
      'data-bs-theme',
      isDarkMode ? 'light' : 'dark'
    );
  };

  const handleGetRandomPrompt = async () => {
    setLoading(true);
    console.log(`Getting random prompt! I will send ${selectedPlayers} as playerCount in this dispatch`)
    try {
      await dispatch(getRandomPrompt({ playerCount: selectedPlayers }));  // Pass the selectedPlayers as playerCount
    } catch (error) {
      console.error("Error fetching random prompt:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    toast.warn("Logout successful"); // Show success toast on logout
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'Space') {
        handleGetRandomPrompt();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleFormSubmit = async (formData) => {
    console.log(`Form submission:`, formData);
    try {
      // Dispatch action to create a new prompt
      await dispatch(createPrompt(formData));
      // Close the modal after successful submission
      setShowAddPromptModal(false);
      toast.success(`Success: ${formData.incomingPrompt} added`);
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('An error occurred while submitting the form. Please try again later.');
    }
  };

  return (
    <Container>
      <Row>
        <Prompt randomPrompt={prompt.prompt} userCreator={prompt.user} />
      </Row>

      <Container className="fixed-bottom mb-3">
        <Row className="justify-content-md-center">
          <Col xs="1">
            <OverlayTrigger overlay={<Tooltip id="single-player">One Player</Tooltip>}>
              <Button
                variant="outline-success"
                active={selectedPlayers === 1}
                onClick={() => setSelectedPlayers(1)}
              >
                <FaUser />
              </Button>
            </OverlayTrigger>
          </Col>
          <Col xs="1">
            <OverlayTrigger overlay={<Tooltip id="two-players">Two Players</Tooltip>}>
              <Button
                variant="outline-success"
                active={selectedPlayers === 2}
                onClick={() => setSelectedPlayers(2)}
              >
                <FaUserGroup />
              </Button>
            </OverlayTrigger>
          </Col>
          <Col xs="1">
            <OverlayTrigger overlay={<Tooltip id="three-players">Three Players</Tooltip>}>
              <Button
                variant="outline-success"
                active={selectedPlayers === 3}
                onClick={() => setSelectedPlayers(3)}
              >
                <FaUsers />
              </Button>
            </OverlayTrigger>
          </Col>
          <Col xs="1">
            <OverlayTrigger overlay={<Tooltip id="All players">Show all prompts</Tooltip>}>
              <Button
                variant="outline-success"
                active={selectedPlayers === 0}
                onClick={() => setSelectedPlayers(0)}
              >
                <MdGroupOff />
              </Button>
            </OverlayTrigger>
          </Col>
          <Col xs="1">
            <OverlayTrigger overlay={<Tooltip id="getRandomPrompt">Get a new prompt</Tooltip>}>
              <Button onClick={handleGetRandomPrompt} disabled={loading} variant="outline-success">
                <FaRandom />
              </Button>
            </OverlayTrigger>
          </Col>
          <Col xs="1">
            {user ? (
              <Dropdown>
                <Dropdown.Toggle variant="success" id="userMenu">
                  <MdEngineering />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={handleShowAddPromptModal}>
                    <OverlayTrigger overlay={
                      <Tooltip id="addNewPrompt">
                        Add your own
                      </Tooltip>}
                    >
                      <Button variant="outline-success">
                        <MdAddBox />
                      </Button>
                    </OverlayTrigger>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={handleShowReportModal}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="reportPrompt">
                          Report this prompt
                        </Tooltip>}>
                      <Button variant="outline-danger">
                        <MdReport />
                      </Button>
                    </OverlayTrigger>
                  </Dropdown.Item>
                  {user.isAdmin &&
                    <Dropdown.Item>
                      <OverlayTrigger overlay={<Tooltip id="editPrompt">Edit Prompt</Tooltip>}>
                        <Button variant="outline-danger"><MdEdit /></Button>
                      </OverlayTrigger>
                    </Dropdown.Item>
                  }
                  <Dropdown.Item onClick={handleLogout}>
                    <OverlayTrigger overlay={<Tooltip id="logout">Logout</Tooltip>}>
                      <Button variant="outline-danger"><MdLogout /></Button>
                    </OverlayTrigger>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <OverlayTrigger overlay={<Tooltip id="login">Login</Tooltip>}>
                <Button variant="outline-success" onClick={handleShowLoginModal}>
                  <MdLogin />
                </Button>
              </OverlayTrigger>
            )}
          </Col>
          <Col xs="1">
            <OverlayTrigger overlay={<Tooltip id="returnToImproviser">Return to Improviser</Tooltip>}>
              <Link to="/">
                <Button variant="outline-secondary">
                  <FaArrowRotateLeft />
                </Button>
              </Link>
            </OverlayTrigger>
          </Col>
          <Col xs="1">
            <OverlayTrigger overlay={<Tooltip id="visionMode">Test: {isDarkMode}</Tooltip>}>
              <Button variant="outline-secondary" onClick={toggleDarkMode}>
                {isDarkMode ? (
                  <IconContext.Provider value={{ color: "yellow" }}>
                    <MdOutlineLightMode />
                  </IconContext.Provider>
                ) : (
                  <IconContext.Provider value={{}}>
                    <MdDarkMode />
                  </IconContext.Provider>
                )}
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      </Container>

      <Modal show={showLoginModal} onHide={handleCloseLoginModal}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Login handleCloseLoginModal={handleCloseLoginModal} />
        </Modal.Body>
      </Modal>
      <Modal show={showReportModal} onHide={handleCloseReportModal}>
        <Modal.Header closeButton>
          <Modal.Title>Report Prompt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Report handleCloseReportModal={handleCloseReportModal} reportedItem={prompt} />
        </Modal.Body>
      </Modal>
      <Modal show={showAddPromptModal} onHide={handleCloseAddPromptModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Prompt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddPrompt userId={user._id} handleCloseAddPromptModal={handleCloseAddPromptModal} handleSubmit={handleFormSubmit} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Prompts;

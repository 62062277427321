import React from 'react';
import { OverlayTrigger, Button, Popover, Col, Row, Tooltip } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { FaClipboard, FaExternalLinkAlt, FaVolumeUp } from 'react-icons/fa';

function ItemExplainer({ item, user, handlePlayback, handleClipboard }) {
  if (!item || !user) {
    return null;
  }

  return (
    <OverlayTrigger
      trigger="click"
      key={item.name || item.name || item.name}
      placement="bottom"
      overlay={
        <Popover id="placeExplainer">
          <Popover.Header as="h3">
            <Container>
              <Row className="descriptionRow">
                <div className="descriptionTitle">Description:</div><br />
                <p className="descriptionContent">
                  {item.description && item.description.length > 0 ? (
                    <>
                      {item.description}
                    </>
                  ) : (
                    <>
                      No Description found
                    </>
                  )}
                </p>
              </Row>
            </Container>
          </Popover.Header>
          <Popover.Body>
            <Container>
              <Col>
                <Row>
                  <Col>
                    <OverlayTrigger
                      placement="bottom"
                      key="dictionary"
                      overlay={
                        <Tooltip id="dictionaryTooltip">
                          Dictionary.com
                        </Tooltip>
                      }>
                      <Button
                        href={"https://www.dictionary.com/browse/" + (item.name || item.name || item.name)}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Open Dictionary.com for definition"
                      >
                        <FaExternalLinkAlt />
                      </Button>
                    </OverlayTrigger>

                  </Col>
                  <Col>
                    <OverlayTrigger
                      placement="bottom"
                      key="copy"
                      overlay={
                        <Tooltip id="copyTooltip">
                          Copy to clipboard
                        </Tooltip>
                      }>
                      <Button
                        onClick={handleClipboard}
                        aria-label={`Copy ${item.name || item.name || item.name}`}
                      >
                        <FaClipboard />
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    <OverlayTrigger
                      placement="bottom"
                      key="speak"
                      overlay={
                        <Tooltip id="speakTooltip">
                          Say it aloud
                        </Tooltip>
                      }>
                      <Button
                        onClick={() => handlePlayback(item.name || item.name || item.name)}
                        aria-label="Speak Description Aloud"
                      >
                        <FaVolumeUp />
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <h6 className="createdByContent">Created by {user}</h6>
                </Row>
                <Row>
                  <h6 className="timesSpunContent">{item.name || item.name || item.name} has been spun up {item.timesUsed} times</h6>
                </Row>
              </Col>
            </Container>
          </Popover.Body>
        </Popover>
      }
    >
      <h2 className="resultName">{item.name || item.name || item.name}</h2>
    </OverlayTrigger>
  );
}

export default ItemExplainer;

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getRandomJob, reset } from '../features/job/jobSlice';
import Job from '../components/Job';
import Place from '../components/Place';
import { Col, Container, Row } from 'react-bootstrap';
import Thing from '../components/Thing';
import Emotion from '../components/Emotion';

function Dashboard() {
  return (
    <>
      <Container >
        <Row>
          <Col sm>
            <Job />
          </Col>
          <Col sm>
            <Place />
          </Col>
          <Col sm>
            <Thing />
          </Col>
        </Row>
        <Row>
          <Col sm>
            <Emotion />
          </Col>
          <Col sm>
            <Row as={Link} to="/gamechanger">TEST</Row>
          </Col>
          <Col sm></Col>
        </Row>
        <hr />
      </Container>
    </>
  );
}

export default Dashboard;

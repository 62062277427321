import { configureStore } from '@reduxjs/toolkit'

import jobReducer from '../features/job/jobSlice'
import placeReducer from '../features/place/placeSlice'
import authReducer from '../features/auth/authSlice'
import thingReducer from '../features/thing/thingSlice'
import promptReducer from '../features/prompt/promptSlice'
import emotionReducer from '../features/emotion/emotionSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    job: jobReducer,
    place: placeReducer,
    thing: thingReducer,
    prompt: promptReducer,
    emotion: emotionReducer,
  },
});
import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaUser, FaSignInAlt } from 'react-icons/fa'
import { login, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import { Container, Button, Form } from 'react-bootstrap'

const initialValues = {
  prompt: '',
};

const INITIAL_ERRORS = {
  prompt: '',
};

function AddPrompt({ handleCloseAddPromptModal, userId, handleSubmit }) {
  const [sliderValue, setSliderValue] = useState(1);
  const [values, setValues] = useState({ ...initialValues });
  const [errors, setErrors] = useState(INITIAL_ERRORS);

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const persistedErrors = JSON.parse(localStorage.getItem('validationErrors')) || {};
    setErrors(persistedErrors);
  }, []);

  const handleSliderChange = (e) => {
    setSliderValue(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // Prepare formData to be submitted
    const formData = {
      ...values,
      userId,
      sliderValue,
    };

    handleSubmit(formData);
    handleCloseAddPromptModal();
  };

  return (
    <Container>
      <section className='form'>
        <Form onSubmit={onSubmit}>
          <p>Writing a prompt helps other improvisers! Just keep it simple, and avoid very niche subjects</p>
          <Form.Group controlId='prompt'>
            <Form.Control
              as='textarea'
              name='incomingPrompt'
              value={values.incomingPrompt}
              placeholder='Please write your prompt in English, be creative!'
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='players'>
            <Form.Range
              value={sliderValue}
              name='players'
              min="1"
              max="3"
              onChange={handleSliderChange}
              tooltipPlacement='top'
              tooltip='on'
            />
            <p>Players: {sliderValue}</p>
          </Form.Group>
          <Form.Group>
            <Button type='submit' variant='success' block>
              Submit Prompt
            </Button>
          </Form.Group>
        </Form>
      </section>
    </Container>
  )
}

export default AddPrompt;
import { Col, Container, Row } from "react-bootstrap";


function Footer() {

  const getYear = () => {
    return new Date().getFullYear();
  }

  return (
    <>
      <Container className="footer fixed-bottom">
        <Row>
          <Col>
            <h6 className="copyright"><span>&copy;</span> {getYear()} Newsworthy Vision Ltd. <br />
              <a href="mailto:development@newsworthyvision.com">Email</a></h6>
          </Col>
          <Col className="donation">
            <form action="https://www.paypal.com/donate" method="post" target="_top">
              <input type="hidden" name="hosted_button_id" value="BW8ZC3ZHMMA4A" />
              <a href="https://www.paypal.com/donate" target="_blank" rel="noreferrer">
                <input type="image" src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
              </a>
              <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </Col>
          <Col>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Footer;
import { createSlice, createAsyncThunk, buildCreateSlice } from "@reduxjs/toolkit";
import thingService from "./thingService";

import axios from "axios";

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;

const initialState = {
  thing: null,
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get Random Thing
export const getRandomThing = createAsyncThunk(
  'things/getRandom',
  async (_, thunkAPI) => {
    try {
      const thingResponse = await thingService.getRandomThing()
      const userResponse = await axios.get(`${API_URL}user/${thingResponse.userCreator}`);
      return {
        thing: thingResponse,
        user: userResponse.data.name
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.messave ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Find things that need verification
export const findThingsToVerify = createAsyncThunk(
  'things/findThingsToVerify',
  async (_, thunkAPI) => {
    try {      
      const token = thunkAPI.getState().auth.user.token
      const thingResponse = await thingService.findThingsToVerify(token)
      // This has to be different from getRandom, as it is getting a list of stuff
      // const userResponse = await axios.get(`${API_URL}user/${thingResponse.userCreator}`);
      const users = await Promise.all(thingResponse.map(async (thing) => {
        const userResponse = await axios.get(`${API_URL}user/${thing.userCreator}`);
        return userResponse.data.name; // Return only the username
      }));
      return {
        thing: thingResponse,
        user: users
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.messave ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteThing = createAsyncThunk(
  'things/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await thingService.deleteThing(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const verifyThing = createAsyncThunk(
  'things/verify',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await thingService.verifyThing(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const createThing = createAsyncThunk(
  'things/create',
  async (incomingData, thunkAPI) => {
    console.log(`Attempting to create a new thing using ${JSON.stringify(incomingData)}`);
    try {
      const token = thunkAPI.getState().auth.user.token
      console.log(`token? ${token}`)
      return await thingService.createThing(incomingData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)

    }
  }
)

export const getAllThings = createAsyncThunk(
  'things/findAll',
  async (_, thunkAPI) => {
    console.log(`Attempting to get all things.`)
    try {
      const token = thunkAPI.getState().auth.user.token
      console.log(`token? ${token}`)
      const response = await thingService.getAllThings(token); // Fetch all things
      return response; // Return the entire response object
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const editThing = createAsyncThunk(
  'things/edit',
  async ({ incomingData, id }, thunkAPI) => {
    console.log(`Attempting to edit a thing: ${incomingData}`)
    try {
      const token = thunkAPI.getState().auth.user.token
      console.log(`token? ${token}`)
      const response = await thingService.editThing(incomingData, id, token);
      console.log(`Response? ${response}`)
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const thingSlice = createSlice({
  name: 'thing',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRandomThing.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getRandomThing.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.thing = action.payload.thing;
        state.user = action.payload.user
      })
      .addCase(getRandomThing.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(createThing.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createThing.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
      })
      .addCase(createThing.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
      })
      .addCase(findThingsToVerify.pending, (state) => {
        state.isLoading = true
      })
      .addCase(findThingsToVerify.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.thing = action.payload.thing;
      })
      .addCase(findThingsToVerify.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
      })
      .addCase(deleteThing.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteThing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Filter out the deleted thing from the state
        state.thing = state.thing.filter((thing) => thing._id !== action.payload._id);
      })
      .addCase(deleteThing.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(verifyThing.pending, (state) => {
        state.isLoading = true
      })
      .addCase(verifyThing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Filter out the verified thing from the state
        state.thing = state.thing.map((thing) =>
          thing._id === action.payload._id ? { ...thing, verified: true } : thing
        );
      })
      .addCase(verifyThing.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getAllThings.pending, (state) => {
        state.isLoading = true
        state.thing=[]
      })
      .addCase(getAllThings.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.thing = action.payload;
        state.user = action.payload.user
      })
      .addCase(getAllThings.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(editThing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editThing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Find the index of the edited thing
        const index = state.thing.findIndex(thing => thing._id === action.payload._id);
        // If the thing is found, update it
        if (index !== -1) {
          state.thing[index] = action.payload;
        }
      })
      .addCase(editThing.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
})

export const { reset } = thingSlice.actions
export default thingSlice.reducer
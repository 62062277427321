import { createSlice, createAsyncThunk, buildCreateSlice } from "@reduxjs/toolkit";
import emotionService from "./emotionService";

import axios from "axios";

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;

const initialState = {
  emotion: null,
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get Random Emotion
export const getRandomEmotion = createAsyncThunk(
  'emotions/getRandom',
  async (_, thunkAPI) => {
    try {
      const emotionResponse = await emotionService.getRandomEmotion()
      const userResponse = await axios.get(`${API_URL}user/${emotionResponse.userCreator}`);
      return {
        emotion: emotionResponse,
        user: userResponse.data.name
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.messave ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Find emotions that need verification
export const findEmotionsToVerify = createAsyncThunk(
  'emotions/findEmotionsToVerify',
  async (_, thunkAPI) => {
    try {      
      const token = thunkAPI.getState().auth.user.token
      const emotionResponse = await emotionService.findEmotionsToVerify(token)
      // This has to be different from getRandom, as it is getting a list of stuff
      // const userResponse = await axios.get(`${API_URL}user/${emotionResponse.userCreator}`);
      const users = await Promise.all(emotionResponse.map(async (emotion) => {
        const userResponse = await axios.get(`${API_URL}user/${emotion.userCreator}`);
        return userResponse.data.name; // Return only the username
      }));
      return {
        emotion: emotionResponse,
        user: users
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.messave ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteEmotion = createAsyncThunk(
  'emotions/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await emotionService.deleteEmotion(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const verifyEmotion = createAsyncThunk(
  'emotions/verify',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await emotionService.verifyEmotion(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const createEmotion = createAsyncThunk(
  'emotions/create',
  async (incomingData, thunkAPI) => {
    console.log(`Attempting to create a new emotion using ${JSON.stringify(incomingData)}`);
    try {
      const token = thunkAPI.getState().auth.user.token
      console.log(`token? ${token}`)
      return await emotionService.createEmotion(incomingData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)

    }
  }
)

export const getAllEmotions = createAsyncThunk(
  'emotions/findAll',
  async (_, thunkAPI) => {
    console.log(`Attempting to get all emotions.`)
    try {
      const token = thunkAPI.getState().auth.user.token
      console.log(`token? ${token}`)
      const response = await emotionService.getAllEmotions(token); // Fetch all emotions
      return response; // Return the entire response object
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const editEmotion = createAsyncThunk(
  'emotions/edit',
  async ({ incomingData, id }, thunkAPI) => {
    console.log(`Attempting to edit a emotion: ${incomingData}`)
    try {
      const token = thunkAPI.getState().auth.user.token
      console.log(`token? ${token}`)
      const response = await emotionService.editEmotion(incomingData, id, token);
      console.log(`Response? ${response}`)
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const emotionSlice = createSlice({
  name: 'emotion',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRandomEmotion.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getRandomEmotion.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.emotion = action.payload.emotion;
        state.user = action.payload.user
      })
      .addCase(getRandomEmotion.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(createEmotion.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createEmotion.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
      })
      .addCase(createEmotion.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
      })
      .addCase(findEmotionsToVerify.pending, (state) => {
        state.isLoading = true
      })
      .addCase(findEmotionsToVerify.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.emotion = action.payload.emotion;
      })
      .addCase(findEmotionsToVerify.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
      })
      .addCase(deleteEmotion.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteEmotion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Filter out the deleted emotion from the state
        state.emotion = state.emotion.filter((emotion) => emotion._id !== action.payload._id);
      })
      .addCase(deleteEmotion.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(verifyEmotion.pending, (state) => {
        state.isLoading = true
      })
      .addCase(verifyEmotion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Filter out the verified emotion from the state
        state.emotion = state.emotion.map((emotion) =>
          emotion._id === action.payload._id ? { ...emotion, verified: true } : emotion
        );
      })
      .addCase(verifyEmotion.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getAllEmotions.pending, (state) => {
        state.isLoading = true
        state.emotion=[]
      })
      .addCase(getAllEmotions.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.emotion = action.payload;
        state.user = action.payload.user
      })
      .addCase(getAllEmotions.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(editEmotion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editEmotion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Find the index of the edited emotion
        const index = state.emotion.findIndex(emotion => emotion._id === action.payload._id);
        // If the emotion is found, update it
        if (index !== -1) {
          state.emotion[index] = action.payload;
        }
      })
      .addCase(editEmotion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
})

export const { reset } = emotionSlice.actions
export default emotionSlice.reducer
import { FaSignInAlt, FaSignOutAlt, FaUser } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { Container, Button, Dropdown, DropdownButton, Row, Col, DropdownHeader, ButtonGroup, SplitButton, Modal } from 'react-bootstrap'
import { useState } from 'react'
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";
import { IconContext } from 'react-icons/lib'
import Login from '../pages/Login'

function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const [showLoginModal, setShowLoginModal] = useState(false); // State to manage modal visibility
  const handleShowLoginModal = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);

  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
  }

  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
    document.documentElement.setAttribute(
      'data-bs-theme',
      isDarkMode ? 'light' : 'dark'
    );
  };

  return (
    <Container className='header'>
      <Row>
        <Col xs={1} className='logo'>

        </Col>
        <Col xs={10} className='userMenu'>
          <Dropdown as={ButtonGroup} autoClose="outside">
            <Button variant="primary" className="mainMenuButton" id="mainMenuButton" size="lg" as={Link} to="/" >Improviser!</Button>
            <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" drop="down-centered" />
            <Dropdown.Menu>
              {user ? (
                <>

                  <Dropdown.ItemText>
                    <strong>Hello {user && user.name}!</strong>
                  </Dropdown.ItemText>
                  <Dropdown.Divider />
                  {user.isAdmin ?
                    <>
                      <Dropdown.Item as={Link} to="/admin">Admin</Dropdown.Item>
                    </>
                    :
                    <>
                    </>}
                  <Dropdown.Item as={Link} to="/edituser">Edit Account</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item>
                    <span onClick={onLogout}>
                      <FaSignOutAlt /> Logout
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to='/about'>About Improviser</Dropdown.Item>
                </>
              ) : (
                <>

                  <Dropdown.Item onClick={handleShowLoginModal} to="/login">
                    <FaSignInAlt /> Login
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/register">
                    <FaUser /> Register
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to='/about'>About Improviser</Dropdown.Item>
                </>
              )}
              <Dropdown.Item onClick={toggleDarkMode}>
                <Button variant="secondary">
                  {isDarkMode ?
                    <>

                      <IconContext.Provider value={{ color: "yellow", size: "1.5em" }}>
                        <MdOutlineLightMode /> Light mode
                      </IconContext.Provider>

                    </>
                    :
                    <>
                      <IconContext.Provider value={{ color: "yellow", size: "1.5em" }}>
                        <MdOutlineDarkMode /> Dark mode
                      </IconContext.Provider>
                    </>
                  }
                </Button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </Col>
        <Col xs={1}>


        </Col>
      </Row>
      <Modal show={showLoginModal} onHide={handleCloseLoginModal}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Login handleCloseLoginModal={handleCloseLoginModal} />
        </Modal.Body>
      </Modal>
    </Container >
  )
}

export default Header
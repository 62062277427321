import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaUser, FaSignInAlt } from 'react-icons/fa'
import { login, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import { Container, Button, Form } from 'react-bootstrap'

function Report({ handleCloseReportModal, reportedItem }) {

  const navigate = useNavigate()
  const dispatch = useDispatch()



  return (
    <Container>
      <section className='form'>
        <Form
        // onSubmit={ }
        >
          We're sorry you have to report something. Sometimes people suck. Please explain in the text box why the following is being reported:

          {reportedItem.prompt.prompt}
          {/* <br />
            {reportedItem.prompt._id} */}

          <Form.Group controlId='explanation'>
            <Form.Control
              as='textarea'
              name='explanation'
              // value={email}
              placeholder='Please explain in English what you feel needs reporting'
            // onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <Button type='submit' variant='danger' block>
              Submit Report
            </Button>
          </Form.Group>
        </Form>
      </section>
    </Container >
  )
}

export default Report
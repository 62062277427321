import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Button, Spinner, Modal, ModalHeader, Container } from 'react-bootstrap';
import { getRandomEmotion } from '../features/emotion/emotionSlice';
import ItemExplainer from './ItemExplainer';
import { useSpeechSynthesis } from 'react-speech-kit';
import NewThingForm from './NewThingForm';
import { createEmotion } from '../features/emotion/emotionSlice';
import { toast } from 'react-toastify';

function Emotion() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  const emotion = useSelector((state) => state.emotion.emotion);
  const userCreator = useSelector((state) => state.emotion.user);
  const user = useSelector((state) => state.auth.user)

  const handleGetRandomEmotion = async () => {
    setLoading(true);
    try {
      await dispatch(getRandomEmotion());
    } catch (error) {
      console.error('Error fetching random emotion:', error);
    } finally {
      setLoading(false);
    }
  };

  const { speak } = useSpeechSynthesis();

  const handlePlayback = (text) => {
    try {
      console.log(`Trying to say ${text}`);
      speak({ text });
    } catch (error) {
      console.error('Error during speech synthesis:', error);
    }
  };

  const handleClipboard = () => {
    const itemToCopy = emotion.name;
    if (!itemToCopy) {
      return;
    }
    navigator.clipboard.writeText(itemToCopy).then(
      function () {
        alert(`Copied ${itemToCopy} to clipboard`);
      })
      .catch(
        function () {
          alert("Error copying to clipboard!");
        });
  };

  const toggleModal = () => setShowModal(!showModal); // Function to toggle modal visibility

  const handleFormSubmit = async (formData) => {
    console.log(`Form submission ${formData}`)
    // Handle form submission
    try {
      // Dispatch action to create a new emotion
      await dispatch(createEmotion(formData));
      // Close the modal after successful submission
      setShowModal(false);
      toast.success(`Success: ${formData.incomingName} added`);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error('A emotion with the same name already exists.');
      } else {
        console.error('Error submitting form:', error);
        toast.error('An error occurred while submitting the form. Please try again later.');
      }
    }
  };

  return (
    <>
      <Col className="bordered d-grid gap-2">
        <Row className="itemTitle">
          <h2>Emotions!</h2>
        </Row>
        <Row className="randomEmotion">
          <Button onClick={handleGetRandomEmotion} disabled={loading} size="lg">
            {loading ?
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {'Loading...'} </>
              : 'Get Random Emotion'}
          </Button>
        </Row>
        <Row className="display">
          {emotion && (
            <Button
              variant="info"
            >
              <div>
                <ItemExplainer item={emotion} user={userCreator} handlePlayback={handlePlayback} handleClipboard={handleClipboard} />
              </div>
            </Button>
          )}
        </Row>
        <hr />
        <Row>
          <Col>
            {user ? (
              <>
                <Button onClick={toggleModal}>Add your own</Button>
                <Modal show={showModal} onHide={toggleModal}> {/* Modal component with show and onHide props */}
                  <Modal.Header closeButton>
                    <Modal.Title>Add New Emotion</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <NewThingForm userId={user._id} onSubmit={handleFormSubmit} closeModal={toggleModal} />
                  </Modal.Body>
                </Modal>
              </>
            ) : (
              <>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default Emotion;

import axios from 'axios'

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL + "places/": process.env.REACT_APP_DEV_API_URL + "places/";

// Get random place

const getRandomPlace = async () => {
  console.log("getRandomPlace activated")
  console.log(`API_URL = ${API_URL}`)
  const response = await axios.get(API_URL + `random`)
  return response.data
}

// Create new place

const createPlace = async (incomingData, token) => {
  console.log("createPlace activated")
  console.log(`API_URL = ${API_URL}`)
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_URL + `create`, incomingData, config)

  return response.data
}

// Edit place

const editPlace = async (incomingData, placeId, token) => {
  console.log("editPlace activated")
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.put(API_URL + placeId, incomingData, config)
  return response.data
}

// Get places that need verification

const findPlacesToVerify = async (token) => {
  console.log("Finding places that need verifying");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL + `verify`, config)
  return response.data
}

const deletePlace = async (placeId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + placeId, config)

  return response.data
}

const verifyPlace = async (placeId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const updatedStatus = { verified: true};

  const response = await axios.put(API_URL + placeId, updatedStatus, config)
  return response.data
}

const placeService = {
  getRandomPlace,
  createPlace,
  findPlacesToVerify,
  deletePlace,
  verifyPlace,
  editPlace,
}

export default placeService
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { MdRecordVoiceOver } from "react-icons/md";
import { useSpeechSynthesis } from "react-speech-kit";

function Prompt({ randomPrompt, userCreator }) {
  const { speak } = useSpeechSynthesis();

  useEffect(() => {
    if (randomPrompt) {
      // Play the ding!
      const audio = new Audio("/gameChangerDing.wav");
      audio.play().catch((error) => {
        console.error("Error playing sound:", error);
      })
    }
  }, [randomPrompt]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'KeyV') {
        handlePlayback(randomPrompt.prompt);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!randomPrompt) {
    return null;
  }

  const handlePlayback = (text) => {
    try {
      console.log(`Trying to say ${text}`);
      speak({ text });
    } catch (error) {
      console.error('Error during speech synthesis:', error);
    }
  };



  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative"
      }}
    >
      <Col
        className="prompt"
        id="useGameChangerFont"
        style={{
          fontSize: "5em",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full height of the viewport
          textAlign: "center",
          flexDirection: "column", // Stack items vertically
          flexGrow: 1,
        }}
      >
        <Row>
          {randomPrompt.prompt}
        </Row>
        <Row>
          <Button variant="outline-secondary" onClick={() => handlePlayback(randomPrompt.prompt || randomPrompt.prompt || randomPrompt.prompt)}
            aria-label="Speak Description Aloud"><MdRecordVoiceOver /></Button>
        </Row>
        <Row
          id="promptStats"
          style={{
            position: "absolute",
            bottom: 50,
            width: "100%", // Make sure it spans the full width of the parent
            textAlign: "center" // Center the text in the row
          }}
        >
          <Col
            id="submittedBy" style={{ fontSize: "0.25em" }}
          >
            Submitted by: {userCreator}
          </Col>
          <Col id="seenBy" style={{ fontSize: "0.25em" }}>
            This has been seen {randomPrompt.timesUsed}
            {randomPrompt.timesUsed === 1 ? " time" : " times"}
          </Col>
          <Col id="players" style={{ fontSize: "0.25em" }}>
            {randomPrompt.players} {randomPrompt.players === 1 ? " player" : " players"}
          </Col>
        </Row>
      </Col >
    </Container>
  );
}

export default Prompt;

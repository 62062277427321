import axios from 'axios'

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL + "emotions/" : process.env.REACT_APP_DEV_API_URL + "emotions/";


// Get random emotion

const getRandomEmotion = async () => {
  console.log("getRandomEmotion activated")
  console.log(`API_URL = ${API_URL}`)
  const response = await axios.get(API_URL + `random`)
  return response.data
}

// Create new emotion

const createEmotion = async (incomingData, token) => {
  console.log("createEmotion activated")
  console.log(`API_URL = ${API_URL}`)
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_URL + `create`, incomingData, config)

  return response.data
}

// Edit emotion

const editEmotion = async (incomingData, emotionId, token) => {
  console.log("editJob activated")
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.put(API_URL + emotionId, incomingData, config)
  return response.data
}

// Get emotions that need verification

const findEmotionsToVerify = async (token) => {
  console.log("Finding emotions that need verifying");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL + `verify`, config)
  // console.log(response.data)
  return response.data
}

const deleteEmotion = async (emotionId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  try {
    const response = await axios.delete(API_URL + emotionId, config)
    return response.data
  } catch (error) {
    throw error; // Throw any errors
  }
}

const verifyEmotion = async (emotionId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const updatedStatus = { verified: true };

  const response = await axios.put(API_URL + emotionId, updatedStatus, config)
  return response.data
}

const getAllEmotions = async (token) => {
  console.log(`getAllEmotions activated`)
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + `all`, config)
  // console.log(`getAllEmotions response = ${JSON.stringify(response.data)}`)
  return response.data
}

const emotionService = {
  getRandomEmotion,
  createEmotion,
  findEmotionsToVerify,
  deleteEmotion,
  verifyEmotion,
  getAllEmotions,
  editEmotion,
}

export default emotionService
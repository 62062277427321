import { Row, Container } from "react-bootstrap"

const About = () => {
  return (
    <Container>
      <Row>
        <h2>About Improviser</h2>
        <p align="left">
          This was a project I got to work on whilst I was hospitalised, and had not much else to do except play around on my laptop with React & Redux.
        </p>
        <p align="left">
          I'm well and truly into all things Improv thanks for the team at <a href="https://www.hooplaimpro.com/" target="_blank" rel="noreferrer">Hoopla Improv</a> in London, and sometimes you just don't have an audience to hand you a suggestion!
        </p>
        <p align="left">
          So, this website was built. I hope it brings you many funny prompts to help you practice or perform. Please add your own suggestions!
        </p>
        <hr />

        <h3>Known bugs</h3>
        <p align="left">- Currently, when you add a suggestion that matches one already in the system, it will act as if it was added. Sadly though, it hasn't been. I'm working on a feedback method to prevent it going through if it matches
        </p>
        <p align="left">- When logged in, you currently can't edit your account information.</p>
        <hr />
        <h3>Things to do</h3>
        <p align="left">- Persistent dark mode</p>
        <p align="left">- More things/places/jobs! (Also remove the generic jobs I added from a database. That's why some are very specific)</p>
        <p align="left">- More stats from the database</p>
        <hr />
        <p>As always, thank you for trying this out. If you have any problems or comments please email <a href="mailto:development@newsworthyvision.com">development@newsworthyvision.com</a></p>


      </Row>
    </Container >
  )
}

export default About
import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaUser, FaSignInAlt } from 'react-icons/fa'
import { login, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import { Container, Button, Form } from 'react-bootstrap'

function Login({ handleCloseLoginModal }) {
  const hasLoggedIn = useRef(false); // Create a ref to track login state
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })

  const { email, password } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if ((isSuccess || user) && !hasLoggedIn.current) {
      hasLoggedIn.current = true; // Set ref to true after successful login
      toast.success("Login successful!");

      // Clear the form or any other post-login logic
      setFormData({
        email: '',
        password: ''
      });

      // Handle closing the modal instead of navigation
      // Assuming `handleCloseModal` is passed as a prop
      handleCloseLoginModal();
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch, handleCloseLoginModal]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password,
    }

    dispatch(login(userData))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Container>
      <section className='heading'>
        <h1>
          <FaSignInAlt /> Login
        </h1>
      </section>
      <section className='form'>
        <Form onSubmit={onSubmit}>
          <Form.Group controlId='email'>
            <Form.Control
              type='email'
              name='email'
              value={email}
              placeholder='Enter your email address'
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group controlId='password'>
            <Form.Control
              type='password'
              name='password'
              value={password}
              placeholder='Enter your password'
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <Button type='submit' variant='primary' block>
              Submit
            </Button>
          </Form.Group>
        </Form>
      </section>
    </Container>
  )
}

export default Login
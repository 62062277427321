import React, { useState } from 'react';
import { Button, Container, Col, Row, Form } from 'react-bootstrap';
import { MdCheck, MdDeleteForever, MdModeEdit } from 'react-icons/md';

const AdminItemTable = ({ items, handleVerify, handleDelete, handleEdit }) => {
  const [editedItem, setEditedItem] = useState(null);

  if (!items || items.length === 0) {
    return <p>No items to display.</p>;
  }

  const handleEditClick = (item) => {
    setEditedItem({ ...item });
  };

  const handleCancelEdit = () => {
    setEditedItem(null);
  };

  const handleSaveEdit = () => {
    handleEdit(editedItem);
    setEditedItem(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  return (
    <Container className='adminItemTable'>
      <Row className='adminItemTableHeader'>
        <Col sm={12} md={1}><strong>Index</strong></Col>
        <Col sm={12} md={2}><strong>Name</strong></Col>
        <Col sm={12} md={4}><strong>Description</strong></Col>
        <Col sm={12} md={2}><strong>Creator</strong></Col>
        <Col sm={6} md={1}><strong>Edit</strong></Col>
        <Col sm={6} md={1}><strong>Verify</strong></Col>
        <Col sm={6} md={1}><strong>Delete</strong></Col>
      </Row>
      {
        items.map((item, index) => (
          <Row key={item._id} className='adminItemTableRow'>
            <Col xs={12} md={1}><strong>{index + 1}</strong></Col>
            <Col xs={12} md={2} className="firstColumnWhenSmall">
              {editedItem && editedItem._id === item._id ? (
                <Form.Control
                  type="text"
                  name="name"
                  value={editedItem.name}
                  onChange={handleInputChange}
                />
              ) : (
                item.name
              )}
            </Col>
            <Col xs={12} md={4} className="secondColumnWhenSmall">
              {editedItem && editedItem._id === item._id ? (
                <Form.Control
                  type="text"
                  name="description"
                  value={editedItem.description}
                  onChange={handleInputChange}
                />
              ) : (
                item.description
              )}
            </Col>
            <Col xs={12} md={2}>{item.creatorName}</Col>
            <Col xs={6} md={1}>
              {editedItem && editedItem._id === item._id ? (
                <>
                  <Button variant="success" onClick={handleSaveEdit}>
                    <MdCheck />
                  </Button>{' '}
                  <Button variant="danger" onClick={handleCancelEdit}>
                    <MdDeleteForever />
                  </Button>
                </>
              ) : (
                <Button variant="primary" onClick={() => handleEditClick(item)}>
                  <MdModeEdit />
                </Button>
              )}
            </Col>
            <Col xs={6} md={1}>
              <Button
                variant="success"
                onClick={() => handleVerify(item)}
                disabled={item.verified}
              >
                <MdCheck />
              </Button>
            </Col>
            <Col xs={6} md={1}>
              <Button variant="danger" onClick={() => handleDelete(item)}>
                <MdDeleteForever />
              </Button>
            </Col>
          </Row>
        ))
      }
    </Container >
  );
};

export default AdminItemTable;

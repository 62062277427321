import { createSlice, createAsyncThunk, buildCreateSlice } from "@reduxjs/toolkit";
import jobService from "./jobService";

import axios from "axios";

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;

const initialState = {
  job: null,
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get Random Job
export const getRandomJob = createAsyncThunk(
  'jobs/getRandom',
  async (_, thunkAPI) => {
    try {
      const jobResponse = await jobService.getRandomJob()
      // console.log(`slice jobResponse: ${JSON.stringify(jobResponse)}`)
      const userResponse = await axios.get(`${API_URL}user/${jobResponse.userCreator}`);
      // console.log(`slice userResponse: ${JSON.stringify(userResponse)}`)
      return {
        job: jobResponse,
        user: userResponse.data.name
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.messave ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Find jobs that need verification
export const findJobsToVerify = createAsyncThunk(
  'jobs/findJobsToVerify',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const jobResponse = await jobService.findJobsToVerify(token)
      // console.log(`slice jobResponse: ${JSON.stringify(jobResponse)}`)
      const users = await Promise.all(jobResponse.map(async (job) => {
        const userResponse = await axios.get(`${API_URL}user/${job.userCreator}`);
        return userResponse.data.name; // Return only the username
      }));

      return {
        job: jobResponse,
        user: users
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.messave ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteJob = createAsyncThunk(
  'jobs/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await jobService.deleteJob(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const verifyJob = createAsyncThunk(
  'jobs/verify',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await jobService.verifyJob(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const createJob = createAsyncThunk(
  'jobs/create',
  async (incomingData, thunkAPI) => {
    console.log(`Attempting to create a new job using ${JSON.stringify(incomingData)}`);
    try {
      const token = thunkAPI.getState().auth.user.token
      console.log(`token? ${token}`)
      return await jobService.createJob(incomingData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)

    }
  }
)

export const getAllJobs = createAsyncThunk(
  'jobs/findAll',
  async (_, thunkAPI) => {
    console.log(`Attempting to get all jobs.`)
    try {
      const token = thunkAPI.getState().auth.user.token
      console.log(`token? ${token}`)
      const response = await jobService.getAllJobs(token); // Fetch all jobs
      return response; // Return the entire response object
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const editJob = createAsyncThunk(
  'jobs/edit',
  async ({ incomingData, id }, thunkAPI) => {
    console.log(`Attempting to edit a job: ${incomingData}`)
    try {
      const token = thunkAPI.getState().auth.user.token
      console.log(`token? ${token}`)
      const response = await jobService.editJob(incomingData, id, token);
      console.log(`Response? ${response}`)
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRandomJob.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getRandomJob.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.job = action.payload.job;
        state.user = action.payload.user
      })
      .addCase(getRandomJob.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(createJob.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createJob.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
      })
      .addCase(createJob.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
      })
      .addCase(findJobsToVerify.pending, (state) => {
        state.isLoading = true
      })
      .addCase(findJobsToVerify.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.job = action.payload.job;
        state.user = action.payload.user
      })
      .addCase(findJobsToVerify.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
      })
      .addCase(deleteJob.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Filter out the deleted job from the state
        state.job = state.job.filter((job) => job._id !== action.payload._id);
      })
      .addCase(deleteJob.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(verifyJob.pending, (state) => {
        state.isLoading = true
      })
      .addCase(verifyJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Filter out the verified job from the state
        state.job = state.job.map((job) =>
          job._id === action.payload._id ? { ...job, verified: true } : job
        );
      })
      .addCase(verifyJob.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getAllJobs.pending, (state) => {
        state.isLoading = true
        state.job = []
      })
      .addCase(getAllJobs.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.job = action.payload;
        state.user = action.payload.user
      })
      .addCase(getAllJobs.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(editJob.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Find the index of the edited job
        const index = state.job.findIndex(job => job._id === action.payload._id);
        // If the job is found, update it
        if (index !== -1) {
          state.job[index] = action.payload;
        }
      })
      .addCase(editJob.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });

  },
})

export const { reset } = jobSlice.actions
export default jobSlice.reducer
import axios from 'axios'

console.log("process.env.NODE_ENV? - " + process.env.NODE_ENV)
const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL + "user/": process.env.REACT_APP_DEV_API_URL + "user/";


// Register user
const register = async (userData) => {
    console.log("Launching register")
    console.log("API_URL = " + API_URL)
    // console.log("userData = " + userData)
  const response = await axios.post(API_URL, userData)
    // console.log("Axios launched with " + userData)
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }

  return response.data
}

// Login user
const login = async (userData) => {
    console.log("Launching register")
    console.log("API_URL = " + API_URL)
    // console.log("userData = " + JSON.stringify(userData))
  const response = await axios.post(API_URL + 'login', userData)
    // console.log("Axios launched with " + userData)
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }

  return response.data
}

// Logout

const logout = () => {
    localStorage.removeItem('user')
}

const authService = {
  register,
  logout,
  login
}

export default authService
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import placeService from "./placeService";

import axios from "axios";

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;


const initialState = {
  place: null,
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get Random place
export const getRandomPlace = createAsyncThunk(
  'places/getRandom',
  async (_, thunkAPI) => {
    try {
      const placeResponse = await placeService.getRandomPlace();
      const userResponse = await axios.get(`${API_URL}user/${placeResponse.userCreator}`);
      console.log(`place userResponse ${JSON.stringify(userResponse)}`)
      return {
        place: placeResponse,
        user: userResponse.data.name
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Find places that need verification
export const findPlacesToVerify = createAsyncThunk(
  'places/findPlacesToVerify',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const placeResponse = await placeService.findPlacesToVerify(token)
      // const userResponse = await axios.get(`${API_URL}user/${placeResponse.userCreator}`);
      const users = await Promise.all(placeResponse.map(async (place) => {
        const userResponse = await axios.get(`${API_URL}user/${place.userCreator}`);
        return userResponse.data.name; // Return only the username
      }));

      return {
        place: placeResponse,
        user: users
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.messave ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deletePlace = createAsyncThunk(
  'place/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await placeService.deletePlace(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const verifyPlace = createAsyncThunk(
  'place/verify',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await placeService.verifyPlace(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const createPlace = createAsyncThunk(
  'place/create',

  async (incomingData, thunkAPI) => {
    console.log(`Attempting to create a new place using ${JSON.stringify(incomingData)}`);
    try {
      const token = thunkAPI.getState().auth.user.token
      return await placeService.createPlace(incomingData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const editPlace = createAsyncThunk(
  'places/edit',
  async ({ incomingData, id }, thunkAPI) => {
    console.log(`Attempting to edit a place: ${incomingData}`)
    try {
      const token = thunkAPI.getState().auth.user.token
      console.log(`token? ${token}`)
      const response = await placeService.editPlace(incomingData, id, token);
      console.log(`Response? ${response}`)
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const placeSlice = createSlice({
  name: 'place',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRandomPlace.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getRandomPlace.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.place = action.payload.place;
        state.user = action.payload.user;
      })
      .addCase(getRandomPlace.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(createPlace.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createPlace.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
      })
      .addCase(createPlace.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
      })
      .addCase(findPlacesToVerify.pending, (state) => {
        state.isLoading = true
      })
      .addCase(findPlacesToVerify.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.place = action.payload.place;
        state.user = action.payload.user
      })
      .addCase(findPlacesToVerify.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
      })
      .addCase(deletePlace.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletePlace.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Filter out the deleted place from the state
        state.place = state.place.filter((place) => place._id !== action.payload._id);
      })
      .addCase(deletePlace.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(verifyPlace.pending, (state) => {
        state.isLoading = true
      })
      .addCase(verifyPlace.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Filter out the verified place from the state
        state.place = state.place.map((place) =>
          place._id === action.payload._id ? { ...place, verified: true } : place
        );
      })
      .addCase(verifyPlace.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(editPlace.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editPlace.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Find the index of the edited place
        const index = state.place.findIndex(place => place._id === action.payload._id);
        // If the place is found, update it
        if (index !== -1) {
          state.place[index] = action.payload;
        }
      })
      .addCase(editPlace.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
})

export const { reset } = placeSlice.actions
export default placeSlice.reducer
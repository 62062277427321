import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createPlace } from '../features/place/placeSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Button } from 'react-bootstrap';

const initialValues = {
  name: '',
  description: ''
};

const INITIAL_ERRORS = {
  incomingName: '',
  incomingDescription: '',
};

function NewThingForm({ userId, onSubmit, closeModal }) {
  const [values, setValues] = useState({ ...initialValues });
  const [errors, setErrors] = useState(INITIAL_ERRORS);
  const dispatch = useDispatch();

  useEffect(() => {
    const persistedErrors = JSON.parse(localStorage.getItem('validationErrors')) || {};
    setErrors(persistedErrors);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (values.name.trim() === '') {
      setErrors({
        incomingName: "You need a name.",
      });
      toast.error("You need a name.");
      return;
    }

    if (values.description.trim() === '') {
      setErrors({
        incomingDescription: "You need a description.",
      });
      toast.error("You need a description.");
      return
    }

    const incomingData = {
      incomingName: values.name,
      incomingDescription: values.description,
      userCreator: userId,
    };
    console.log(`Attempting to dispatch ${incomingData}`)
    onSubmit(incomingData);
    closeModal(false);
    setValues({ ...initialValues });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formGroupName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the name of your job/place/thing"
            name="name"
            value={values.name}
            onChange={handleChange}
            aria-describedby="name-error"
            aria-invalid={errors.incomingName ? "true" : "false"}
          />
          {errors.incomingName && <p className="error">{errors.incomingName}</p>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Please describe your job/place/thing in simple English"
            name="description"
            value={values.description}
            onChange={handleChange}
            aria-describedby="description-error"
            aria-invalid={errors.incomingDescription ? "true" : "false"}
          />
          {errors.incomingDescription && <p className="error">{errors.incomingDescription}</p>}
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
}

export default NewThingForm;

import axios from 'axios'

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL + "things/" : process.env.REACT_APP_DEV_API_URL + "things/";


// Get random thing

const getRandomThing = async () => {
  console.log("getRandomThing activated")
  console.log(`API_URL = ${API_URL}`)
  const response = await axios.get(API_URL + `random`)
  return response.data
}

// Create new thing

const createThing = async (incomingData, token) => {
  console.log("createThing activated")
  console.log(`API_URL = ${API_URL}`)
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_URL + `create`, incomingData, config)

  return response.data
}

// Edit thing

const editThing = async (incomingData, thingId, token) => {
  console.log("editJob activated")
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.put(API_URL + thingId, incomingData, config)
  return response.data
}

// Get things that need verification

const findThingsToVerify = async (token) => {
  console.log("Finding things that need verifying");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL + `verify`, config)
  // console.log(response.data)
  return response.data
}

const deleteThing = async (thingId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  try {
    const response = await axios.delete(API_URL + thingId, config)
    return response.data
  } catch (error) {
    throw error; // Throw any errors
  }
}

const verifyThing = async (thingId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const updatedStatus = { verified: true };

  const response = await axios.put(API_URL + thingId, updatedStatus, config)
  return response.data
}

const getAllThings = async (token) => {
  console.log(`getAllThings activated`)
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + `all`, config)
  // console.log(`getAllThings response = ${JSON.stringify(response.data)}`)
  return response.data
}

const thingService = {
  getRandomThing,
  createThing,
  findThingsToVerify,
  deleteThing,
  verifyThing,
  getAllThings,
  editThing,
}

export default thingService